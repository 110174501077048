import WebServiceRequest from '../Api/WebServiceRequest'

class PaymentDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('Payment/Delete?id='+id)
    }
}
class PaymentGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Payment/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class PaymentGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('Payment/Get?id='+id)
    }
}
export  {
    PaymentDeleteRequest,
    PaymentGetAllRequest,
    PaymentGetRequest
};
